import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "../Assets/MenuIcon.png"

export default function DesayunosMenu() {
    const categoriaButton = " sm:h-[55px] md:h-10 bg-violet-500 hover:bg-violet-700 cursor-pointer"
    const categoriaText = "xl:ml-3 xl:mt-1 sm:text-md md:text-lg tracking-normal inline-block sm:text-justify md:text-center text-white"

    const CategoriaButtonMini = function () {
        if (menuVisible) {
            return "absolute flex flex-col left-0 top-[75px]"
        } else {
            return "absolute hidden left-0 top-[75px]"
        }
    }

    const haswindow = window.innerWidth;
    //767
    function getWindowsDimesion() {
        const widht = haswindow ? window.innerWidth : null;
        return widht
    }
    
    const [windowAncho, setWindowAncho] = useState(getWindowsDimesion())
    const [menuVisible, setMenuVisible] = useState(false)
    useEffect(() => {
        if (haswindow) {
            function handleResize() {
                setWindowAncho(getWindowsDimesion())
            }
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize)
        }
    }, [haswindow])

    function EnableDisableMenu() {
        if (menuVisible) {
            setMenuVisible(false)
        } else {
            setMenuVisible(true)
        }
    }

    return (
        <div className="z-[50]">
            <div className={windowAncho < 767 ?
                " absolute left-0 top-[0px]"
                :
                "hidden"
            }>
                <img src={MenuIcon} alt="Menu_alt" className="w-[80px] h-[80px] cursor-pointer block p-5" onClick={() => EnableDisableMenu()} />
            </div>
            <div className={windowAncho < 767 ?
                CategoriaButtonMini()
                :
                "flex flex-col md:flex sm:w-[200px] md:w-[300px] lg:w-[300px] xl:w-[400px]"}>
                <div>
                    <h3 className={windowAncho < 767 ?
                        "hidden"
                        :
                        "text-[25px] text-violet-600 font-bold shadow-lg"
                    }>Regalos a Domicilio</h3>
                </div>

                <Link to="/Domicilio?category=sanvalentin" className=" sm:h-[55px] md:h-10 bg-violet-500 hover:bg-violet-700 cursor-pointer rounded-t-lg">
                    <h3 className={categoriaText}>San Valentin 2025</h3>
                </Link>

                {/** 
                <Link to="/Domicilio?category=madre" className=" sm:h-[55px] md:h-10 bg-violet-500 hover:bg-violet-700 cursor-pointer rounded-t-lg">
                    <h3 className={categoriaText}>DIA DE LA MADRE 2024</h3>
                </Link>
                */}

                {/**<Link to="/Domicilio?category=navidad" className=" sm:h-[55px] md:h-10 bg-violet-500 hover:bg-violet-700 cursor-pointer rounded-t-lg">
                    <h3 className={categoriaText}>NAVIDAD 2024</h3>
                </Link>
                */}
                
                <Link to="/Domicilio?category=desayuno&merienda" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Desayunos Y Meriendas</h3>
                </Link>

                <Link to="/Domicilio?category=aperitivos" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Aperitivos</h3>
                </Link>

                <Link to="/Domicilio?category=infantil" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Desayunos Infantiles</h3>
                </Link>

                <Link to="/Domicilio?category=brindis" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Brindis</h3>
                </Link>

                <Link to="/Domicilio?category=bombones" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Bombones Peluches Flores</h3>
                </Link>

                <Link to="/Domicilio?category=flores" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Flores</h3>
                </Link>

                <Link to="/Domicilio?category=nacimiento" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Set Nacimiento</h3>
                </Link>

                <Link to="/Domicilio?category=delikatessen" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Estuches con Delikatessen</h3>
                </Link>

                <Link to="/Domicilio?category=vino&copas" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Estuches con Vinos y Copas</h3>
                </Link>

                <Link to="/Domicilio?category=canasta&baules" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Canastas y Baules Premium</h3>
                </Link>

                <Link to="/Domicilio?category=especiales" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Regalos Especiales</h3>
                </Link>

                <Link to="/Domicilio?category=opcionesparaagregar" className=" sm:h-[55px] md:h-10 bg-violet-500 hover:bg-violet-700 cursor-pointer rounded-b-lg" onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Opciones para Agregar a tus Regalos</h3>
                </Link>
            </div>
        </div>
    )
}