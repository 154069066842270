import React, { useEffect, useState } from "react";
import TopBar from "./TopBar";
import CategoriaBar from "./CategoriesBar";
import TierCard from "./tierCard";
import { useCreateProductMutation } from "../../AppStore/ApiSlice"
import { useNavigate } from "react-router-dom";

export default function CreateProd() {
    const [tipoOpt, setTipoOpt] = useState("domicilio");
    const [totalTiers, setTotalTiers] = useState();
    const [mainImage, setMainImage] = useState();
    const [extraImages, setExtraImages] = useState([]);

    const [createTask, { isLoading, isSuccess }] = useCreateProductMutation();

    function ImgTo64(e) {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            setMainImage(reader.result);
        }
    }

    function ImgTo64ExtraImgs(e) {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            setExtraImages([...extraImages, reader.result])
        }
    }

    function ChanginfType(e) {
        e.preventDefault();
        setTipoOpt(e.target.value)
    }

    function ShowCategorias() {
        if (tipoOpt === "domicilio") {
            return (
                <>
                    <select name="Categoria" className="w-[200px]">
                        <option value="sanvalentin">San Valentin</option>
                        <option value="navidad">Navidad</option>
                        <option value="madre">Dia de la Madre</option>
                        <option value="desayuno&merienda">Desayuno y Meriendas</option>
                        <option value="aperitivos">Aperitivos</option>
                        <option value="infantil">Desayunos Infantiles</option>
                        <option value="brindis">Brindis</option>
                        <option value="bombones">Bombones, Peluches y Rosas</option>
                        <option value="flores">Flores</option>
                        <option value="nacimiento">Set Nacimiento</option>
                        <option value="delikatessen">Estuches con Delikatessen</option>
                        <option value="vino&copas">Estuches con Vino y Copas</option>
                        <option value="canasta&baules">Canastas y Baules Premium</option>
                        <option value="especiales">Regalos Especiales</option>
                        <option value="opcionesparaagregar">Opciones para Agregar a tus Regalos</option>
                    </select>
                </>)
        } else {
            return (
                <>
                    <select name="Categoria" className="w-[200px]">
                        <option value="accesorio">Accesorios para Vino</option>
                        <option value="agendas&portfolios">Agendas / PortFolios</option>
                        <option value="outside&personal">Aire Libre / Uso Personal</option>
                        <option value="asado">Asado</option>
                        <option value="botellas&jarros">Botellas & Jarros</option>
                        <option value="empcan&deli">Canastas y delicatesse</option>
                        <option value="estuches&cajas">Estuches Personalizados</option>
                        <option value="boligrafos">Boligrafos</option>
                        <option value="escritiorio">Escritorio</option>
                        <option value="decoracion&hogar">Decoracion / Hogar</option>
                        <option value="marroquineria">Marroquineria</option>
                        <option value="mates">Mates</option>
                        <option value="mujer">Mujer</option>
                        <option value="relojes&portapapeles">Relojes y Portapapeles</option>
                        <option value="tecnologia&pc">Tecnologia y PC</option>
                        <option value="varios">Varios</option>
                    </select>
                </>)
        }
    }

    function OnHandleChangeNumberTiers(e) {
        e.preventDefault();
        setTotalTiers(e.target.value);
    }

    function ShowObservationCards() {
        let item = []
        for (let i = 0; i < totalTiers; i++) {
            item.push(<TierCard number={i} test={i} key={i} />)
        }
        return item;
    }

    const OnHandleSummit = async (e) => {
        e.preventDefault();
        const Nombre = e.target.elements.Nombre.value.trim();
        const Tipo = e.target.elements.Tipo.value;
        const Foto = mainImage;
        const ImgExtra = extraImages;
        const Categoria = e.target.elements.Categoria.value;
        const Descripcion = e.target.elements.Descripcion.value.trim();
        const Detalle = e.target.elements.Detalle.value.split(/\r?\n|\r|\n/g);
        const Precio = e.target.elements.Precio.value;
        const Disponible = e.target.elements.Disponible.checked;
        const Visible = e.target.elements.Visible.checked;
        const Observacion = e.target.elements.Observacion.value.trim();
        const Tier = [];
        if (totalTiers >= 1) {
            Tier.push({
                Descripcion: e.target.elements.Descripcion_0.value.trim(),
                Disponible: e.target.elements.Disponible_0.checked,
                Precio: e.target.elements.Precio_0.value,
                Detalle: e.target.elements.Detalle_0.value.split(/\r?\n|\r|\n/g)
            })
        }
        if (totalTiers >= 2) {
            Tier.push({
                Descripcion: e.target.elements.Descripcion_1.value.trim(),
                Disponible: e.target.elements.Disponible_1.checked,
                Precio: e.target.elements.Precio_1.value,
                Detalle: e.target.elements.Detalle_1.value.split(/\r?\n|\r|\n/g)
            })
        }
        if (totalTiers >= 3) {
            Tier.push({
                Descripcion: e.target.elements.Descripcion_2.value.trim(),
                Disponible: e.target.elements.Disponible_2.checked,
                Precio: e.target.elements.Precio_2.value,
                Detalle: e.target.elements.Detalle_2.value.split(/\r?\n|\r|\n/g)
            })
        }
        if (totalTiers >= 4) {
            Tier.push({
                Descripcion: e.target.elements.Descripcion_3.value.trim(),
                Disponible: e.target.elements.Disponible_3.checked,
                Precio: e.target.elements.Precio_3.value,
                Detalle: e.target.elements.Detalle_3.value.split(/\r?\n|\r|\n/g)
            })
        }
        if (totalTiers >= 5) {
            Tier.push({
                Descripcion: e.target.elements.Descripcion_4.value.trim(),
                Disponible: e.target.elements.Disponible_4.checked,
                Precio: e.target.elements.Precio_4.value,
                Detalle: e.target.elements.Detalle_4.value.split(/\r?\n|\r|\n/g)
            })
        }

        if (Nombre != null && Nombre != "" && Foto != null && Precio != "" && Precio != null) {
            createTask({ Nombre, Tipo, Foto, ImgExtra, Categoria, Descripcion, Detalle, Observacion, Precio, Disponible, Visible, Tier });
            alert("Comfirmado! Revise que se paso bien la informacion")
        } else {
            alert("Error! Verifique los Datos")
        }

    }
    return (
        <>
            <TopBar />
            <CategoriaBar />
            <div className="w-3/4 bg-slate-700 mx-auto my-2 rounded-xl">
                <form className=" mx-auto w-[80%] rounded-xl flex flex-col" onSubmit={OnHandleSummit}>

                    <div className=" bg-gray-200 flex flex-col my-2 h-[100px] rounded-xl">
                        <span className="font-bold ">Nombre:</span>
                        <span className=" text-red-500 text-sm/[5px]">*Obligatorio</span>
                        <p>Nombre del Producto a bender</p>
                        <input type="text" name="Nombre" placeholder="Insertar Nombre de Producto" className="border-2 border-black" />
                    </div>

                    <div className=" bg-gray-200 flex flex-col my-2 h-[100px] rounded-xl">
                        <span className="font-bold ">Tipo:</span>
                        <p>Designar a que parte de Imperial pertence.</p>
                        <select name="Tipo" className="w-[200px]" onChange={(e) => ChanginfType(e)}>
                            <option value="domicilio" >Domicilio</option>
                            <option value="empresarial">Empresarial</option>
                        </select>
                    </div>

                    <div className=" bg-gray-200 flex flex-col my-2 rounded-xl">
                        <span className="font-bold ">Foto:</span>
                        <span className=" text-red-500 text-sm/[5px]">*Obligatorio</span>
                        <p>Asignar una Foto</p>
                        <input name="Imagen" type="file" onChange={ImgTo64} />
                        {mainImage == "" || mainImage == null ? "" : <img className="h-[150px] w-[200px] m-2" src={mainImage} alt="main_Image" />}

                    </div>

                    <div className=" bg-gray-200 flex flex-col my-2 rounded-xl">
                        <span className="font-bold ">Imagenes Adicionales:</span>
                        <p>Asignar una Foto</p>
                        <input name="Imagen" type="file" onChange={ImgTo64ExtraImgs} />
                        <div className="flex">
                            {extraImages == [] || extraImages == null ? "" : extraImages.map((e, i) => {
                                return (
                                    <img className="h-[150px] w-[200px] m-2 cursor-pointer" src={e} key={i} alt="extra_image" onClick={(e) => {
                                        const temp = [...extraImages];
                                        temp.splice(i, 1);
                                        setExtraImages(temp)
                                    }} />
                                )
                            })
                            }
                        </div>

                    </div>

                    <div className=" bg-gray-200 flex flex-col my-2 h-[100px] rounded-xl">
                        <span className="font-bold ">Categoria:</span>
                        <p>Asignar a que categoria de regalo pertenece.</p>
                        {ShowCategorias()}
                    </div>

                    <div className=" bg-gray-200 flex flex-col my-2 h-[100px] rounded-xl">
                        <span className="font-bold ">Descripcion:</span>
                        <p>Agregar una descripcion simple del producto</p>
                        <input type="text" name="Descripcion" placeholder="Agregar una descripcion" className="border-2 border-black" />
                    </div>

                    <div className=" bg-gray-200 flex flex-col my-2 h-auto rounded-xl">
                        <span className="font-bold ">Detalle:</span>
                        <p>Enumere cuales son los contenidos del pedido.</p>
                        <textarea name="Detalle" placeholder="Agregar Detalles" className=" rounded-xl border-black border-[1.5px]" />
                    </div>

                    <div className=" bg-gray-200 flex flex-col my-2 h-[100px] rounded-xl">
                        <span className="font-bold ">Precio:</span>
                        <span className=" text-red-500 text-sm/[5px]">*Obligatorio</span>
                        <p>Asigne un Precio al producto.</p>
                        <input type="number" name="Precio" className="border-2 border-black no-spinner" onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} />
                    </div>

                    <div className=" bg-gray-200 flex flex-col my-2 h-[100px] rounded-xl">
                        <span className="font-bold ">Disponible:</span>
                        <p>Defina si el producto esta actualmente disponible para la venta. Una tilde significa que si.</p>
                        <input type="checkbox" name="Disponible" className="border-2 border-black " />
                    </div>

                    <div className=" bg-gray-200 flex flex-col my-2 h-[100px] rounded-xl">
                        <span className="font-bold ">Visible:</span>
                        <p>Defina si el producto es visible en la pagina. Una tilde significa que si.</p>
                        <input type="checkbox" name="Visible" className="border-2 border-black " />
                    </div>

                    <div className=" bg-gray-200 flex flex-col my-2 h-auto rounded-xl">
                        <span className="font-bold ">Observacion:</span>
                        <p>Agregar alguna observacion para su envio o algun dato particular.</p>
                        <textarea name="Observacion" placeholder="Agregar Observacion" className=" rounded-xl resize-y overflow-auto border-2 border-black" />
                    </div>

                    <div className=" bg-gray-200 flex flex-col my-2 h-auto rounded-xl">
                        <span className="font-bold ">Agregados:</span>
                        <p>Describa y enumere cuantos Agregados tiene este producto.</p>
                        <select onChange={(e) => OnHandleChangeNumberTiers(e)} name="Ntiers">
                            <option value={"0"}>0</option>
                            <option value={"1"}>1</option>
                            <option value={"2"}>2</option>
                            <option value={"3"}>3</option>
                            <option value={"4"}>4</option>
                            <option value={"5"}>5</option>
                        </select>
                        {ShowObservationCards()}
                    </div>
                    <div>
                        <button className=" bg-slate-200 rounded-md hover:bg-slate-500 w-[20%] mx-auto">Agregar a la Base de Datos.</button>
                    </div>

                </form >
            </div >

        </>
    )
} 