import React from "react";
import Card from "./Card";
import { useGetProductsQuery, useGetProductsByCategoryQuery, useGetProductsByTypeQuery } from "../AppStore/ApiSlice";
import { useSearchParams } from "react-router-dom";

export default function CardList() {

    const [getQueryType] = useSearchParams();
    const [getQueryCategory] = useSearchParams();
    let type = getQueryType.get("type");
    let category = getQueryCategory.get("category")
    const { data: producto, isError, isLoading, error } = useGetProductsQuery();
    const { data: productoType, isError: typeisError, isLoading: typeisLoading, error: typeerror } = useGetProductsByTypeQuery(type);
    const { data: productoCategory, isError: CategoryisError, isLoading: CategoryLoaging, error: Categoryerror } = useGetProductsByCategoryQuery(category);

    if (typeisLoading) return <div>Cargando...</div>;
    if (CategoryLoaging) return <div>Cargando...</div>
    else if (isError) return <div>Error: {error.message}</div>;

    return (
        <div className="w-full mx-2 m-2 rounded-xl
        grid grid-cols-2 sm:grid-cols-2 md:gris-cold-2 lg:grid-cols-3  xl:grid-cols-4 2xl:grid-cols-3 gap-x-5 gap-y-3">
            {productoType ? <>
                {productoType.length > 0 ?
                    productoType.map((prod, i) => {
                        if (prod.Visible == true) {
                            return <Card Producto={prod} key={i} />
                        }
                    }) :
                    productoCategory.map((prod, i) => {
                        if (prod.Visible == true) {
                            return <Card Producto={prod} key={i} />
                        }

                    })
                }</> : <></>}
        </div>

    )
}