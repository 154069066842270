import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "../Assets/MenuIcon.png"

export default function DesayunosMenu() {
    const categoriaButton = " sm:h-[55px] md:h-10 bg-purple-500 hover:bg-purple-700 cursor-pointer"
    const categoriaText = "ml-3 mt-1 text-lg tracking-normal inline-block text-center text-white"
    const CategoriaButtonMini = function () {
        if (menuVisible) {
            return "absolute flex flex-col left-0 top-[75px]"
        } else {
            return "absolute hidden left-0 top-[75px]"
        }
    }

    const haswindow = window.innerWidth;
    //767

    function getWindowsDimesion() {
        const widht = haswindow ? window.innerWidth : null;
        return widht
    }

    const [windowAncho, setWindowAncho] = useState(getWindowsDimesion())
    const [menuVisible, setMenuVisible] = useState(false)
    useEffect(() => {
        if (haswindow) {
            function handleResize() {
                setWindowAncho(getWindowsDimesion())
            }
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize)
        }
    }, [haswindow])

    function EnableDisableMenu() {
        if (menuVisible) {
            setMenuVisible(false)
        } else {
            setMenuVisible(true)
        }
    }

    return (
        <div className="flex flex-col">
            <div className={windowAncho < 767 ?
                " absolute left-0 top-[0px]"
                :
                "hidden"
            }>
                <img src={MenuIcon} alt="Menu_alt" className="w-[80px] h-[80px] cursor-pointer block p-5" onClick={() => EnableDisableMenu()} />
            </div>
            <div className={windowAncho < 767 ?
                CategoriaButtonMini()
                :
                "flex flex-col md:flex sm:w-[200px] md:w-[300px] lg:w-[300px] xl:w-[400px]"}>
                <div>
                    <h3 className="text-[25px] text-purple-600 font-bold shadow-lg">Regalos Empresariales</h3>
                </div>

                <Link to="/Empresarial?category=accesorio" className="h-10 bg-purple-500 hover:bg-purple-700 cursor-pointer rounded-t-lg" onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Accesorios para vinos</h3>
                </Link>

                <Link to="/Empresarial?category=agendas&portfolios" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Agendas / portfolios</h3>
                </Link>

                <Link to="/Empresarial?category=outside&personal" className={categoriaButton} onClick={() => EnableDisableMenu()}> 
                    <h3 className={categoriaText}>Aire Libre / Uso Personal</h3>
                </Link>

                <Link to="/Empresarial?category=asado" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Asado</h3>
                </Link>

                <Link to="/Empresarial?category=botellas&jarros" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Botellas/Jarros</h3>
                </Link>

                <Link to="/Empresarial?category=empcan&deli" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Canastas / Delikatessen</h3>
                </Link>

                <Link to="/Empresarial?category=estuches&cajas" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Estuches Vino / Copas</h3>
                </Link>

                <Link to="/Empresarial?category=boligrafos" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Boligrafos</h3>
                </Link>

                <Link to="/Empresarial?category=escritiorio" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Escritorio</h3>
                </Link>

                <Link to="/Empresarial?category=decoracion&hogar" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Decoracion / Hogar</h3>
                </Link>

                <Link to="/Empresarial?category=marroquineria" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Marroquineria</h3>
                </Link>

                <Link to="/Empresarial?category=mates" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Mates</h3>
                </Link>

                <Link to="/Empresarial?category=mujer" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Mujer</h3>
                </Link>

                <Link to="/Empresarial?category=elojes&portapapeles" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Relojes y Portalapices</h3>
                </Link>

                <Link to="/Empresarial?category=tecnologia&pc" className={categoriaButton} onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Tecnologia y PC </h3>
                </Link>

                <Link to="/Empresarial?category=varios" className="h-10 bg-purple-500 hover:bg-purple-700 cursor-pointer rounded-b-lg" onClick={() => EnableDisableMenu()}>
                    <h3 className={categoriaText}>Varios</h3>
                </Link>
            </div>
        </div >
    )
}