import CategoriaBar from "./CategoriesBar";
import TopBar from "./TopBar";
import { useGetProductsQuery, useDeleteTaskMutation, useGetProductsByTypeQuery, useGetProductsByCategoryQuery } from "../../AppStore/ApiSlice";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function AdminProductoList() {
    const [tipoOpt, setTipoOpt] = useState("domicilio");
    const [category, setCategory] = useState("");
    const { data: producto, isError, isLoading, error } = useGetProductsQuery();
    const { data: productoType, isError: typeisError, isLoading: typeisLoading, error: typeerror } = useGetProductsByTypeQuery(tipoOpt);
    const { data: productoCategory, isError: CategoryisError, isLoading: CategoryLoaging, error: Categoryerror } = useGetProductsByCategoryQuery(category);

    const [deleteTasks] = useDeleteTaskMutation();
    const [input, setInput] = useState();




    const [currentName, setCurrentName] = useState();
    const [currentId, setCurrentId] = useState();
    let [popUpTail, setPopUpTail] = useState("bg-blue-500 fixed top-[50%] left-[50%] w-[500px] h-[150px] rounded-xl flex flex-col hidden z-[500]");

    if (isLoading) return <div>is loading</div>;
    else if (isError) return <div>Error: {error.message}</div>;

    function PrepareDelete(name, id) {
        setCurrentName(name)
        setCurrentId(id)

        setPopUpTail("bg-blue-500 fixed top-[50%] left-[50%] w-[500px] h-[150px] rounded-xl flex flex-col visible z-[500]")
    }
    function NoDelete() {
        setPopUpTail("bg-blue-500 fixed top-[50%] left-[50%] w-[500px] h-[150px] rounded-xl flex flex-col hidden z-[500]")
    }

    function DeleteSelectedProd(e) {
        deleteTasks(e)
        setPopUpTail("bg-blue-500 fixed top-[50%] left-[50%] w-[500px] h-[150px] rounded-xl flex flex-col hidden")
    }

    function ChanginfType(e) {
        e.preventDefault();
        setTipoOpt(e.target.value)
    }

    function FilteringProducts(e) {
        setCategory(e.target.value)
    }

    function ShowCategorias() {
        if (tipoOpt === "domicilio") {
            return (
                <>
                    <select name="Categoria" className="bg-blue-500 hover:bg-blue-700 w-[120px] h-[30px] rounded-lg my-2 mx-3 text-white font-bold" onChange={(e) => FilteringProducts(e)}>
                        <option value="">Select</option>
                        <option value="sanvalentin">San Valentin</option>
                        <option value="madre">Dia de la Madre</option>
                        <option value="navidad">Navidad</option>
                        <option value="desayuno&merienda">Desayuno y Meriendas</option>
                        <option value="aperitivos">Aperitivos</option>
                        <option value="infantil">Desayunos Infantiles</option>
                        <option value="brindis">Brindis</option>
                        <option value="bombones">Bombones, Peluches y Rosas</option>
                        <option value="flores">Flores</option>
                        <option value="nacimiento">Set Nacimiento</option>
                        <option value="delikatessen">Estuches con Delikatessen</option>
                        <option value="vino&copas">Estuches con Vino y Copas</option>
                        <option value="canasta&baules">Canastas y Baules Premium</option>
                        <option value="especiales">Regalos Especiales</option>
                        <option value="opcionesparaagregar">Opciones para Agregar a tus Regalos</option>
                    </select>
                </>)
        } else {
            return (
                <>
                    <select name="Categoria" className="bg-blue-500 hover:bg-blue-700 w-[120px] h-[30px] rounded-lg my-2 mx-3 text-white font-bold" onChange={(e) => FilteringProducts(e)}>
                        <option value="">Select</option>
                        <option value="accesorio">Accesorios para Vino</option>
                        <option value="agendas&portfolios">Agendas / PortFolios</option>
                        <option value="outside&personal">Aire Libre / Uso Personal</option>
                        <option value="asado">Asado</option>
                        <option value="botellas&jarros">Botellas & Jarros</option>
                        <option value="empcan&deli">Canastas y delicatesse</option>
                        <option value="estuches&cajas">Estuches Personalizados</option>
                        <option value="boligrafos">Boligrafos</option>
                        <option value="escritiorio">Escritorio</option>
                        <option value="decoracion&hogar">Decoracion / Hogar</option>
                        <option value="juegos">Juegos</option>
                        <option value="marroquineria">Marroquineria</option>
                        <option value="mates">Mates</option>
                        <option value="mujer">Mujer</option>
                        <option value="relojes&portapapeles">Relojes y Portapapeles</option>
                        <option value="tecnologia&pc">Tecnologia y PC</option>
                        <option value="varios">Varios</option>
                    </select>
                </>)
        }
    }

    /**const FilterProd = () => {
        if (category == "") {
            {
                productoType.map((prod, i) => {
                    return (
                        <tr key={i} className=" bg-white">
                            <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{prod._id}</th>
                            <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{prod.Nombre}</th>
                            <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{prod.Tipo}</th>
                            <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">${prod.Precio}</th>
                            <th className="flex justify-around border-b-[1px] border-black">

                                <Link to={`/admin/log/Edit?id=${prod._id}`} >
                                    <button className=" bg-yellow-700 rounded-full w-[100px] h-[30px] text-white ">Editar</button>
                                </Link>

                                <button className=" bg-red-700 rounded-full w-[100px] h-[30px] text-white" onClick={() => PrepareDelete(prod.Nombre, prod._id)}>Eliminar</button>

                            </th>
                        </tr>
                    )
                })
            }
        } else {
            {
                productoCategory.map((prod, i) => {
                    return (
                        <tr key={i} className=" bg-white">
                            <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{prod._id}</th>
                            <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{prod.Nombre}</th>
                            <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{prod.Tipo}</th>
                            <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">${prod.Precio}</th>
                            <th className="flex justify-around border-b-[1px] border-black">

                                <Link to={`/admin/log/Edit?id=${prod._id}`} >
                                    <button className=" bg-yellow-700 rounded-full w-[100px] h-[30px] text-white ">Editar</button>
                                </Link>

                                <button className=" bg-red-700 rounded-full w-[100px] h-[30px] text-white" onClick={() => PrepareDelete(prod.Nombre, prod._id)}>Eliminar</button>

                            </th>
                        </tr>
                    )
                })
            }
        }*/

    return (
        <div className="z-[500]">
            <TopBar />
            <CategoriaBar />
            <div className="w-3/4 bg-slate-600 m-auto">
                <div className="flex">
                    <Link to={"/admin/log/create"}>
                        <button className="bg-blue-500 hover:bg-blue-700 w-[120px] h-[30px] rounded-lg my-2 mx-3 text-white font-bold">Crear Nuevo</button>
                    </Link>
                    {/*
                    <div className="flex">
                        <input type="text" value={input} onChange={(e) => { handleChange(e) }} placeholder='Busca un Producto...' />
                        <button>Buscar</button>
                    </div>*/}

                    <select className="bg-blue-500 hover:bg-blue-700 w-[120px] h-[30px] rounded-lg my-2 mx-3 text-white font-bold" onChange={(e) => ChanginfType(e)}>
                        <option value="domicilio" >Domicilio</option>
                        <option value="empresarial">Empresarial</option>
                    </select>

                    {ShowCategorias()}
                </div>
                <table className=" w-full">
                    <thead>
                        <tr>
                            <th className=" border-2 border-black w-[5%] pointer cursor-pointer">ID</th>
                            <th className=" border-2 border-black pointer cursor-pointer">Nombre</th>
                            <th className=" border-2 border-black pointer cursor-pointer">Tipo</th>
                            <th className=" border-2 border-black pointer cursor-pointer">Precio</th>
                            <th className=" border-2 border-black">Edicion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {category == "" ?
                            productoType?.map((prod, i) => {
                                return (
                                    <tr key={i} className=" bg-white">
                                        <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{prod._id}</th>
                                        <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{prod.Nombre}</th>
                                        <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{prod.Tipo}</th>
                                        <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">${prod.Precio}</th>
                                        <th className="flex justify-around border-b-[1px] border-black">

                                            <Link to={`/admin/log/Edit?id=${prod._id}`} >
                                                <button className=" bg-yellow-700 rounded-full w-[100px] h-[30px] text-white ">Editar</button>
                                            </Link>

                                            <button className=" bg-red-700 rounded-full w-[100px] h-[30px] text-white" onClick={() => PrepareDelete(prod.Nombre, prod._id)}>Eliminar</button>

                                        </th>
                                    </tr>
                                )
                            })
                            :
                            productoCategory?.map((prod, i) => {
                                return (
                                    <tr key={i} className=" bg-white">
                                        <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{prod._id}</th>
                                        <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{prod.Nombre}</th>
                                        <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{prod.Tipo}</th>
                                        <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">${prod.Precio}</th>
                                        <th className="flex justify-around border-b-[1px] border-black">

                                            <Link to={`/admin/log/Edit?id=${prod._id}`} >
                                                <button className=" bg-yellow-700 rounded-full w-[100px] h-[30px] text-white ">Editar</button>
                                            </Link>

                                            <button className=" bg-red-700 rounded-full w-[100px] h-[30px] text-white" onClick={() => PrepareDelete(prod.Nombre, prod._id)}>Eliminar</button>

                                        </th>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            <div className={popUpTail}>
                <h3 className=" text-center text-white mt-2">¿Esta Seguro que quiere borrar {currentName}?</h3>
                <div className="mt-auto mb-5 flex justify-center">
                    <button className=" bg-green-500 hover:bg-green-700 w-[200px] h-[30px] rounded-lg mr-2" onClick={() => NoDelete()}>No</button>
                    <button className=" bg-red-500 hover:bg-red-700 w-[200px] h-[30px] rounded-lg ml-2" onClick={() => DeleteSelectedProd(currentId)}>Si</button>
                </div>
            </div>
        </div>
    )
}

